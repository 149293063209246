/* LOGIN */
.login {
  background-image: url(../images/college.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.login::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.66);
}
/* .main {
  height: calc((100vh) - 65px);
  background-image: url(../images/backschool.jpg) !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom right;
} */
.inputLogin {
  border-bottom: 1px solid #bbb !important;
}

.inputLogin input {
  color: #fff !important;
}

.inputLogin label {
  color: #bbb !important;
}

.formContent {
  width: 75%;
  max-width: 400px;
  color: #fff;
  z-index: 9999;
}
/* LANDING PAGE */
.hero {
  background-image: url(../images/back_school.jpg);
  background-size: cover;
  background-position: top left;
  background-repeat: no-repeat;
}
.hero img {
  height: 100px;
  padding: 15px 0 0 15px;
}

audio {
  width: 100%;
  height: 40px;
}
audio::-webkit-media-controls {
  width: inherit;
  height: inherit;
  position: relative;
  direction: ltr;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
audio::-webkit-media-controls-enclosure {
  width: 100%;
  height: 40px;
  flex-shrink: 0;
  bottom: 0;
  text-indent: 0;
  padding: 0;
  box-sizing: border-box;
}

audio::-webkit-media-controls-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  z-index: 0;
  overflow: hidden;
  text-align: right;
  bottom: auto;
  height: 40px;
  background-color: #ccc;
  border-radius: 5px;
  transition: opacity 0.3s;
}

audio:-webkit-full-page-media {
  max-height: 100%;
}
audio:-webkit-full-page-media::-webkit-media-controls-panel {
  bottom: 0px;
}
audio::-webkit-media-controls-mute-button {
  -webkit-appearance: media-mute-button;
  display: flex;
  flex: none;
  border: none;
  box-sizing: border-box;
  width: 35px;
  line-height: 40px;
  margin: 0 6px 0 0;
  padding: 0;
  background-color: initial;
  color: inherit;
}
audio::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-time-remaining-display {
  -webkit-appearance: media-current-time-display;
  flex: none;
  display: flex;
  border: none;
  cursor: default;
  height: 40px;
  margin: 0 9px 0 0;
  padding: 0;
  line-height: 40px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  color: white;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0;
  text-shadow: none;
  text-decoration: none;
}
audio::-webkit-media-controls-timeline {
  -webkit-appearance: media-slider;
  display: flex;
  flex: 1 1 auto;
  height: 5px;
  margin: 0 15px 0 0;
  padding: 0;
  background-color: #fff;
  border: initial;
  color: inherit;
}

.exam_countdown {
  border-radius: 20px;
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 100px -75px #f2f2f2 inset;
  font-weight: 900;
  font-size: 20px;
  color: #fff;
  background-color: #23286b;
}

.Mui-selected {
  background-color: #ccc !important;
}

/* CUSTOM NOTIFICATION */

.ant-notification-notice-with-icon svg {
  fill: #fff;
}
.ant-notification-notice-success {
  background-color: #52c41a;
}
.ant-notification-notice-info {
  background-color: #1890ff;
}
.ant-notification-notice-error {
  background-color: #ff4d4f;
}
.ant-notification-notice-warning {
  background-color: #faad15;
}
.ant-notification-notice-success,
.ant-notification-notice-info,
.ant-notification-notice-error,
.ant-notification-notice-warning {
  border-radius: 10px;
}
.ant-notification-notice-message {
  color: #fff;
  font-weight: 600;
}
.ant-notification-notice-description {
  color: #eee;
}

/* Select schools */

.select-schools .ant-select-selector {
  cursor: pointer !important;
  border-radius: 5px !important;
  background-color: #23286b !important;
  border: 1px solid #f5f5f5 !important;
  color: #fff !important;
  width: 350px !important;
}

.select-year .ant-select-selector {
  border-radius: 5px !important;
  background-color: #23286b !important;
  border: 1px solid #f5f5f5 !important;
  color: #fff !important;
}

.select-schools .ant-select-arrow,
.select-year .ant-select-arrow {
  color: #fff !important;
  z-index: 9999 !important;
  background-color: #23286b !important;
  width: 15px;
  height: 20px;
}

.select-schools .ant-select-arrow span svg,
.select-year .ant-select-arrow span svg {
  width: 1.5em;
  height: 1.2em;
}

table tr th {
  background: #23286b !important;
  color: white !important;
}

table tr th,
table tr td {
  border: 1px solid rgba(200, 200, 200, 0.5);
}
